/*
 * Footer Messages
 *
 * This contains all the text for the footer component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.footer';

export default defineMessages({
  aboutUs: {
    id: `${scope}.aboutUs`,
    defaultMessage: 'Về Công Ty',
  },
  support: {
    id: `${scope}.support`,
    defaultMessage: 'Hỗ trợ',
  },
  careers: {
    id: `${scope}.careers`,
    defaultMessage: 'Tuyển Dụng',
  },
  downloadOn: {
    id: `${scope}.downloadOn`,
    defaultMessage: 'Tải về trên',
  },
  getItOn: {
    id: `${scope}.getItOn`,
    defaultMessage: 'Tải về trên',
  },
  faqs: {
    id: `${scope}.faqs`,
    defaultMessage: 'FAQs',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Trang Chủ',
  },
  followUs: {
    id: `${scope}.followUs`,
    defaultMessage: 'Kết nối với chúng tôi',
  },
  payment: {
    id: `${scope}.payment`,
    defaultMessage: 'Phương Thức Thanh Toán',
  },
  delivery: {
    id: `${scope}.delivery`,
    defaultMessage: 'Chính Sách Giao Hàng',
  },
  exchange: {
    id: `${scope}.exchange`,
    defaultMessage: 'Chính Sách Đổi Trả',
  },
  allRightReserved: {
    id: `${scope}.allRightReserved`,
    defaultMessage: '© 2024 - Bản quyền thuộc về Công ty TNHH KAMEREO',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage:
      'Địa chỉ: F05&F06A Tầng 1-Tòa nhà The Manor Officetel, 89 Nguyễn Hữu Cảnh, Phường 22, Quận Bình Thạnh, TP.HCM',
  },
  businessRegistrationCertificate: {
    id: `${scope}.businessRegistrationCertificate`,
    defaultMessage: 'Giấy CNĐKDN: 0315000500 - Ngày cấp 19/04/2018 - Cơ quan cấp: Phòng Đăng ký kinh doanh – Sở kế hoạch và Đầu tư TP.HCM',
  
  },
  wholesaleLiquorLicense:{
    id: `${scope}.wholesaleLiquorLicense`,
    defaultMessage: 'Giấy phép bán buôn rượu số 227/GP-SCT - Ngày cấp: 15/06/2023 - Cơ quan cấp: Sở Công Thương - UBND TPHCM',
  },
  kamereoCo: {
    id: `${scope}.kamereoCo`,
    defaultMessage: 'CÔNG TY TNHH KAMEREO.',
  },
  certifications: {
    id: `${scope}.certifications`,
    defaultMessage: 'Chứng Nhận',
  },
  press: {
    id: `${scope}.press`,
    defaultMessage: 'Báo Chí',
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: 'SĐT:',
  },
  firstFooterDescription: {
    id: `${scope}.firstFooterDescription`,
    defaultMessage: 'Với hơn 2000 sản phẩm chất lượng từ',
  },
  lastFooterDescription: {
    id: `${scope}.lastFooterDescription`,
    defaultMessage:
      ', giá cả bình ổn, kèm theo dịch vụ giao hàng chuyên nghiệp, hỗ trợ xuất hóa đơn nhanh chóng, Kamereo là nhà cung cấp uy tín tại TP HCM và Bình Dương dành cho Nhà Hàng, Cà Phê, Khách Sạn, Bar, Trường Học, Siêu Thị,...',
  },
  freshVegetables: {
    id: `${scope}.freshVegetables`,
    defaultMessage: 'rau củ quả tươi',
  },
  kitchenUtensils: {
    id: `${scope}.kitchenUtensils`,
    defaultMessage: 'vật dụng nhà bếp',
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'đến',
  },
  commitFresh: {
    id: `${scope}.commitFresh`,
    defaultMessage: 'Cam Kết Tươi Mới',
  },
  news: {
    id: `${scope}.news`,
    defaultMessage: 'Tin Tức',
  },
  businessTips: {
    id: `${scope}.businessTips`,
    defaultMessage: 'Kinh Doanh F&B',
  },
  service: {
    id: `${scope}.service`,
    defaultMessage: 'Dịch Vụ',
  },
  coreValues:{
    id: `${scope}.coreValues`,
    defaultMessage: 'Giá Trị Cốt Lõi',
  },
  consultingServices: {
    id: `${scope}.consultingServices`,
    defaultMessage: 'Tư vấn dịch vụ',
  },
  reflectingQuality: {
    id: `${scope}.reflectingQuality`,
    defaultMessage: 'Phản ánh chất lượng'
  }
});
